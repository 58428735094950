<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">历史统计分析</h1>
    </div>
    <div slot="extra">
      <a-form layout="inline">
        <a-form-item>
          <area-selection @onclickArea="onclickArea" @search="search"></area-selection>
        </a-form-item>
        <a-form-item label="集污点类型">
          <a-select default-value="ALL"
                    style="width: 130px"
                    @change="selectSpotType"
                    v-model="selectedSpotType">
            <a-select-option value="ALL">全部</a-select-option>
            <a-select-option
              v-for="item in spotTypeList"
              :key="item.value"
              :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="司机">
          <a-tree-select
            :disabled="dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.carId === undefined ? false : true"
            style="width: 150px"
            allowClear
            :treeData="userList"
            v-model="driverStr"
            @change="beforeSearch"
            placeholder="请选择司机"
          >
          </a-tree-select>
        </a-form-item>
        <a-form-item label="车辆">
          <a-select
            :disabled="dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.driverId === undefined ? false : true"
            style="width: 150px"
            allowClear
            showSearch
            optionFilterProp="label"
            v-model="dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.carId"
            placeholder="请选择车辆"
            @change="search"
          >
            <a-select-option v-for="item in carList" :key="item.id" :value="item.id" :label="item.name">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="日期范围">
          <a-range-picker style="width: 260px;" :allowClear="false"
                          format="YYYY-MM-DD"
                          :value="defaultHistoryTime"
                          @change="handleModalPickerOk"></a-range-picker>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" class="m-r-12" @click="searchByData('sevenDays')">近7日</a-button>
          <!--          <a-button type="primary" class="m-r-12" @click="searchByData('currentWeek')">本周</a-button>-->
          <a-button type="primary" class="m-r-12" @click="searchByData('thirtyDays')">近30日</a-button>
          <!--          <a-button type="primary" @click="searchByData('currentMonth')">本月</a-button>-->
        </a-form-item>
      </a-form>
    </div>
    <a-card :bordered="false">
      <a-spin :spinning="spinLoading" tip="加载中...">
        <a-row :gutter="24">
          <a-col :sm="24" :md="12" :lg="6" :xl="6">
            <a-card hoverable @click="currentChartClick('totalLoop')"
                    :style="{ background: currentChart==='totalLoop'?'#d6f7e8':'#ffffff' }">
              <template>
                <a-row type="flex" style="text-align: center; color: grey;font-size: 18px;line-height: 30px;">
                  <a-col :span="24">
                    清运趟数
                  </a-col>
                </a-row>
                <a-row type="flex" style="text-align: center; color: black; font-size: 23px">
                  <a-col :span="24">
                    {{totalLoop}}&nbsp;<span style="font-size: 12px">趟</span>
                  </a-col>
                </a-row>
              </template>
            </a-card>
          </a-col>
          <a-col :sm="24" :md="12" :lg="6" :xl="6">
            <a-card hoverable @click="currentChartClick('totalTon')"
                    :style="{ background: currentChart==='totalTon'?'#d6f7e8':'#ffffff' }">
              <template>
                <a-row type="flex" style="text-align: center; color: grey;font-size: 18px;line-height: 30px;">
                  <a-col :span="24">
                    拉运吨数
                  </a-col>
                </a-row>
                <a-row type="flex" style="text-align: center; color: black; font-size: 23px;">
                  <a-col :span="24">
                    {{totalTon}}&nbsp;<span style="font-size: 12px">吨</span>
                  </a-col>
                </a-row>
              </template>
            </a-card>
          </a-col>
          <a-col :sm="24" :md="12" :lg="6" :xl="6">
            <a-card hoverable @click="currentChartClick('totalTask')"
                    :style="{ background: currentChart==='totalTask'?'#d6f7e8':'#ffffff' }">
              <template>
                <a-row type="flex" style="text-align: center; color: grey;font-size: 18px;line-height: 30px;">
                  <a-col :span="24">
                    任务个数
                  </a-col>
                </a-row>
                <a-row type="flex" style="text-align: center; color: black; font-size: 23px">
                  <a-col :span="24">
                    {{totalTask}}&nbsp;<span style="font-size: 12px">个</span>
                  </a-col>
                </a-row>
              </template>
            </a-card>
          </a-col>
          <a-col :sm="24" :md="12" :lg="6" :xl="6">
            <a-card hoverable @click="currentChartClick('totalMileage')"
                    :style="{ background: currentChart==='totalMileage'?'#d6f7e8':'#ffffff' }">
              <template>
                <a-row type="flex" style="text-align: center; color: grey;font-size: 18px;line-height: 30px;">
                  <a-col :span="24">
                    行驶公里
                  </a-col>
                </a-row>
                <a-row type="flex" style="text-align: center; color: black; font-size: 23px">
                  <a-col :span="24">
                    {{totalMileage}}&nbsp;<span style="font-size: 12px">公里</span>
                  </a-col>
                </a-row>
              </template>
            </a-card>
          </a-col>
        </a-row>
        <a-row :gutter="32" style="margin-top: 16px;">
          <a-col :sm="18" :md="18" :lg="18" :xl="18">
            <div style="margin: 10px 0;font-size: 18px;font-weight: bold;">
              <a-icon type="bar-chart"/>&nbsp;日统计趋势
            </div>
            <div id="loopHistory"></div>
          </a-col>
          <a-col :sm="6" :md="6" :lg="6" :xl="68">
            <div style="margin: 10px 0;font-size: 18px;font-weight: bold;">
              <a-icon type="fund"/>&nbsp;清运排行榜
            </div>
            <div style="height: 280px;overflow: auto;padding-right: 10px;">
              <rank-list title="清运排行榜" :list="rankList"/>
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="24" style="margin-top: 6px;" v-if="
        dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.carId === undefined
        && dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.driverId === undefined
        ">
          <a-col :sm="24" :md="12" :lg="12" :xl="12">
            <a-row>
              <div style="margin: 10px 0;font-size: 18px;font-weight: bold;">
                <a-icon type="car"/>&nbsp;车辆综合统计
              </div>
              <div class="ant-table-wrapper">
                <div class="ant-spin-nested-loading">
                  <div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered">
                    <div class="ant-table-content">
                      <div class="ant-table-body">
                        <table style="text-align: center;width: 100%">
                          <thead class="ant-table-thead">
                          <tr>
                            <th style="text-align: center;width: 130px">车辆牌号</th>
                            <th style="text-align: center;width: 130px">拉运（趟）</th>
                            <th style="text-align: center;width: 130px">数量（吨）</th>
                            <th style="text-align: center;width: 130px">任务（个）</th>
                            <th style="text-align: center;width: 130px">行驶（公里）</th>
                          </tr>
                          </thead>
                          <tbody class="ant-table-tbody">
                          <tr class="ant-table-row ant-table-row-level-0" style="cursor: pointer" v-for="item in carInfoGatherList" @click="carListClick(item.carInfoId)">
                            <td class="ant-table-row-cell-break-word">{{item.carName}}</td>
                            <td class="ant-table-row-cell-break-word">{{item.totalLoop}}</td>
                            <td class="ant-table-row-cell-break-word">{{item.totalTon}}</td>
                            <td class="ant-table-row-cell-break-word">{{item.totalTask}}</td>
                            <td class="ant-table-row-cell-break-word">{{item.totalMileage.toFixed(3)}}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a-row>
          </a-col>
          <a-col :sm="24" :md="12" :lg="12" :xl="12">
            <a-row>
              <div style="margin: 10px 0;font-size: 18px;font-weight: bold;">
                <a-icon type="user"/>&nbsp;司机综合统计
              </div>
              <div class="ant-table-wrapper">
                <div class="ant-spin-nested-loading">
                  <div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered">
                    <div class="ant-table-content">
                      <div class="ant-table-body">
                        <table style="text-align: center;width: 100%">
                          <thead class="ant-table-thead">
                          <tr>
                            <th style="text-align: center;width: 130px">司机姓名</th>
                            <th style="text-align: center;width: 130px">拉运（趟）</th>
                            <th style="text-align: center;width: 130px">数量（吨）</th>
                            <th style="text-align: center;width: 130px">任务（个）</th>
                            <th style="text-align: center;width: 130px">行驶（公里）</th>
                          </tr>
                          </thead>
                          <tbody class="ant-table-tbody">
                          <tr class="ant-table-row ant-table-row-level-0" style="cursor: pointer" v-for="item in driverGatherList" @click="driverListClick(item)">
                            <td class="ant-table-row-cell-break-word">{{item.driverName}}</td>
                            <td class="ant-table-row-cell-break-word">{{item.totalLoop}}</td>
                            <td class="ant-table-row-cell-break-word">{{item.totalTon}}</td>
                            <td class="ant-table-row-cell-break-word">{{item.totalTask}}</td>
                            <td class="ant-table-row-cell-break-word">{{item.totalMileage.toFixed(3)}}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a-row>
          </a-col>
        </a-row>
        <a-table
          v-else
          size="middle"
          :columns="columns"
          :dataSource="tableData"
          :loading="tableLoading"
          :pagination="pagination"
          @change="tableChange"
          :customRow="rowClick"
          :rowKey="(record) => record.id"
          :scroll="{ x: 1700 }">
          <template slot="_index" slot-scope="text, record, index">
            {{ searchParams.number * searchParams.size + index + 1 }}
          </template>
          <template slot="reportNames" slot-scope="text, record">
            <a-tag v-for="(item,index) in record.reportNames" :key="index" color="#E9967A" style="margin: 3px;">{{item}}</a-tag>
          </template>
          <template slot="status" slot-scope="text, record">
            <a-tag v-if="record.status === 'NOT'">未开始</a-tag>
            <a-tag v-if="record.status === 'DOING'" color="#87d068">进行中</a-tag>
            <a-tag v-if="record.status === 'COMPLETED'" color="#108ee9">已完成</a-tag>
          </template>
          <template slot="executeTime" slot-scope="text, record">
            <span>{{ record.startTime }} ~ {{ record.endTime }}</span>
          </template>
          <template slot="carInfo" slot-scope="text, record">
            {{record.carInfo.platNumber}}
          </template>
          <template slot="mileage" slot-scope="text, record">
            {{record.mileage}} |{{record.modifyMileage}}
          </template>
        </a-table>
      </a-spin>
    </a-card>
    <a-drawer title="清运详细信息"
              placement="right"
              :closable="true"
              @close="detailClose"
              :mask="false"
              width="1600px"
              :visible="detailDrawer">
      <DrawerDetail :dbDevicesData="dbDevicesData" ref="viewRef"></DrawerDetail>
    </a-drawer>
  </page-layout>
</template>


<script>
  import PageLayout from '@/components/page/PageLayout'
  import RankList from '@/components/chart/RankList'
  import G2 from '@antv/g2'
  import moment from 'moment'
  import SERVICE_URLS from '@/api/service.url'
  import { carLoopRecordColumns } from '../../reportrecord/looprecord/common/common'
  import DrawerDetail from '../../reportrecord/looprecord/DrawerDetail'

  import AreaSelection from './AreaSelection'
  import { spotTypeList } from '@/constant/spot/spotType'

  export default {
    name: 'LoopHistory',
    components: { PageLayout, RankList, AreaSelection, DrawerDetail },
    data () {
      return {
        spinLoading: false,
        currentChart: 'totalLoop',
        dispatchHistoryStatisticsByAreaNoAndCharttypeDTO: {
          startTime: null,
          endTime: null,
          chartType: 'loop',
          areaNo: [],
          carId: undefined,
          driverId: undefined,
          spotType: undefined
        },
        defaultHistoryTime: null,
        dateSearch: null,
        carInfoGatherList: [],
        driverGatherList: [],
        totalLoop: '',
        totalTon: '',
        totalTask: '',
        totalMileage: '',
        chart: null,
        data: [],
        rankList: [],
        format: 'YYYY-MM-DD',
        yName: 'totalLoop',
        carList: [],
        userList: [],
        driverStr: undefined,
        columns: [],
        pagination: {
          current: 1,
          total: 1,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`
        },
        tableData: [],
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          driverId: undefined,
          carInfoId: undefined,
          status: 'COMPLETED',
          startTime: '',
          endTime: '',
          spotType: undefined
        },
        areaNo: [],
        detailDrawer: false,
        dbDevicesData: [],
        spotTypeList: spotTypeList(),
        selectedSpotType: 'ALL'
      }
    },
    mounted () {
      var m = carLoopRecordColumns().slice()
      m.pop()
      this.columns = m
      this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.startTime = moment().subtract(29, 'days').format('YYYY-MM-DD')
      this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.endTime = moment(new Date()).format('YYYY-MM-DD')
      this.defaultHistoryTime = [this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.startTime, this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.endTime]
      this.search()
      this.initChart()
      this.loadAreaOption()
      this.loadCarInfoList()
      this.loadUserList()
    },
    methods: {
      loadCarInfoList () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.carInfoApi.select,
          noTips: true,
          success: (data) => {
            this.carList = data.body
          }
        })
      },
      loadUserList () {
        this.$http(this, {
          url: SERVICE_URLS.organization.orgUserTree,
          data: {
            authPrefix: 'car_',
            chooseType: 'RADIO'
          },
          noTips: true,
          success: (data) => {
            this.userList = data.body
          }
        })
      },
      loadAreaOption () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.areaDictionaryApi.cascade,
          noTips: true,
          success: (data) => {
            this.areaOption = data.body
          }
        })
      },
      searchByData (type) {
        if (type === 'sevenDays') {
          this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.startTime = moment().subtract(7, 'days').format(this.format)
        } else if (type === 'currentWeek') {
          this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.startTime = moment().startOf('week').format(this.format)
        } else if (type === 'thirtyDays') {
          this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.startTime = moment().subtract(30, 'days').format(this.format)
        } else if (type === 'currentMonth') {
          this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.startTime = moment().startOf('month').format(this.format)
        }
        this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.endTime = moment().format(this.format)
        this.defaultHistoryTime = [this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.startTime, this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.endTime]
        this.search()
      },
      search () {
        this.loadData()
        this.loadChartData()
        this.loopDetail()
      },
      beforeSearch () {
        this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.driverId = this.driverStr ? this.driverStr.split('_')[2] : undefined
        this.search()
      },
      //统计块点击
      currentChartClick (type) {
        this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.chartType = type
        this.currentChart = type
        this.yName = type
        this.getSortData()
        this.chart.clear()
        this.setChartOption(this.chart)
        this.chart.render()
      },
      handleModalPickerOk (value) {
        if (value.length > 0) {
          this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.startTime = value[0].format('YYYY-MM-DD')
          this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.endTime = value[1].format('YYYY-MM-DD')
        } else {
          this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.startTime = ''
          this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.endTime = ''
        }
        this.defaultHistoryTime = [this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.startTime, this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.endTime]
        this.search()
      },
      loadData () {
        this.spinLoading = true
        this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.areaNo = this.areaNo
        this.$http(this, {
          url: SERVICE_URLS.csgz.statisticsApi.dispatch_history_statistics,
          data: this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO,
          noTips: true,
          success: (data) => {
            this.totalLoop = data.body[0].totalLoop
            this.totalTon = data.body[0].totalTon ? data.body[0].totalTon : 0
            this.totalTask = data.body[0].totalTask ? data.body[0].totalTask : 0
            this.totalMileage = data.body[0].totalMileage ? data.body[0].totalMileage.toFixed(3) : 0
            this.spinLoading = false
          }
        })
        this.$http(this, {
          url: SERVICE_URLS.csgz.statisticsApi.history_detail_statistics,
          data: this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO,
          noTips: true,
          success: (data) => {
            this.carInfoGatherList = data.body.carDetails
            this.driverGatherList = data.body.driverDetails
          }
        })
      },
      loadChartData () {
        this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.areaNo = this.areaNo
        this.data = []
        this.$http(this, {
          url: SERVICE_URLS.csgz.statisticsApi.history_chart_detail,
          data: this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO,
          noTips: true,
          success: (data) => {
            this.data = data.body
            this.spinLoading = false
            this.getSortData()
            this.chart.source(this.data)
            this.chart.repaint()

          }
        })
      },
      getSortData () {
        //清运排行榜
        this.rankList = []
        var unit = ''
        if (this.currentChart === 'totalLoop') {
          unit = '趟'
        } else if (this.currentChart === 'totalTon') {
          unit = '吨'
        } else if (this.currentChart === 'totalTask') {
          unit = '个'
        } else if (this.currentChart === 'totalMileage') {
          unit = '公里'
        }
        //排序
        const value = this.currentChart
        this.data.sort(function (a, b) {
          return b[value] - a[value]
        })
        for (let i = 0; i < this.data.length; i++) {
          this.rankList.push({
            name: this.data[i].dayStr,
            total: (this.currentChart === 'totalMileage' ? (this.data[i][this.currentChart]).toFixed(3) : this.data[i][this.currentChart]) + ' ' + unit
          })
        }
      },
      initChart () {
        const chart = new G2.Chart({
          container: 'loopHistory',
          forceFit: true,
          height: 300,
          padding: [50, 50, 50, 50]
        })
        this.setChartOption(chart)
        chart.source(this.data)

        this.chart = chart
        this.chart.render()
      },
      setChartOption (chart) {
        chart.tooltip({
          showTitle: true
        })
        chart.axis('dayStr', {
          tickLine: {
            alignTick: false
          }
        })
        chart.axis(`${this.yName}`, false)
        chart.intervalStack()
          .position('dayStr*' + `${this.yName}`)
          .tooltip('dayStr*' + `${this.yName}`, (item, count) => {
            var name = ''
            var unit = ''
            if (this.currentChart === 'totalLoop') {
              name = '清运'
              unit = '趟'
            } else if (this.currentChart === 'totalTon') {
              name = '数量'
              unit = '吨'
            } else if (this.currentChart === 'totalTask') {
              name = '任务'
              unit = '个'
            } else if (this.currentChart === 'totalMileage') {
              name = '行驶'
              unit = '公里'
            }
            return {
              name: name,
              value: this.currentChart === 'totalMileage' ? count.toFixed(3) : count + unit
            }
          })
        chart.point().position('dayStr*' + `${this.yName}`)
          .tooltip('dayStr*' + `${this.yName}`, (item, count) => {
            var name = ''
            var unit = ''
            if (this.currentChart === 'totalLoop') {
              name = '清运'
              unit = '趟'
            } else if (this.currentChart === 'totalTon') {
              name = '数量'
              unit = '吨'
            } else if (this.currentChart === 'totalTask') {
              name = '任务'
              unit = '个'
            } else if (this.currentChart === 'totalMileage') {
              name = '行驶'
              unit = '公里'
            }
            return {
              name: name,
              value: this.currentChart === 'totalMileage' ? count.toFixed(3) : count + unit
            }
          })
          .label(`${this.yName}`, {
            formatter (text, item, index) {
              return parseFloat(text).toFixed(0)
            },
            content: (originData) => {
              var unit = ''
              if (this.currentChart === 'totalLoop') {
                unit = '趟'
              } else if (this.currentChart === 'totalTon') {
                unit = '吨'
              } else if (this.currentChart === 'totalTask') {
                unit = '个'
              } else if (this.currentChart === 'totalMileage') {
                unit = '公里'
              }
              return this.currentChart === 'totalMileage' ? originData.toFixed(3) : originData + unit
            },
            offset: 20
          })
      },
      loopDetail () {
        this.searchParams.driverId = this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.driverId
        this.searchParams.carInfoId = this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.carId
        this.searchParams.startTime = this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.startTime
        this.searchParams.endTime = this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.endTime
        this.searchParams.areaNo = this.areaNo.join(',')
        this.$http(this, {
          url: SERVICE_URLS.csgz.carLoopRecordApi.search,
          noTips: true,
          loading: 'tableLoading',
          data: this.searchParams,
          success: (data) => {
            this.tableData = data.body.content
            this.pagination.total = data.body.totalElements
            this.pagination.current = data.body.number + 1
            this.pagination.pageSize = data.body.size
            this.searchParams.size = data.body.size
            this.searchParams.number = data.body.number
            this.pagination.total = data.body.totalElements
          }
        })
      },
      tableChange (pagination, filters, sorter) {
        this.searchParams.size = pagination.pageSize
        this.searchParams.number = pagination.current - 1
        if (sorter.order === 'ascend') {
          this.searchParams.direction = 'ASC'
        } else {
          this.searchParams.direction = 'DESC'
        }
        this.searchParams.order = sorter.field
        this.loopDetail()
      },
      carListClick (carInfoId) {
        this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.carId = carInfoId
        this.search()
        this.loopDetail()
      },
      driverListClick (item) {
        this.driverStr = 'USER_' + item.orgId + '_' + item.driverId
        this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.driverId = item.driverId
        this.search()
        this.loopDetail()
      },
      selectSpotType () {
        this.dispatchHistoryStatisticsByAreaNoAndCharttypeDTO.spotType = this.selectedSpotType
        this.searchParams.spotType = this.selectedSpotType
        this.search()
        this.loopDetail()
      },
      onclickArea (areaCodes) {
        this.areaNo = areaCodes
      },
      rowClick (record) {
        return {
          on: {
            click: () => {
              if (record.status === 'COMPLETED') {
                this.platNumber = '车牌号：' + record.carInfo.platNumber
                this.handleView(record.id)
              } else {
                this.$message.warning('任务未完成，详情未生成。')
              }
            }
          }
        }
      },
      detailClose () {
        this.detailDrawer = false
      },
      //详情抽屉
      handleView (id) {
        this.detailDrawer = true
        this.$nextTick(() => {
          if (this.$refs['viewRef']) {
            if (
              typeof this.$refs['viewRef'].loadData !== 'undefined' &&
              this.$refs['viewRef'].loadData instanceof Function
            ) {
              this.$refs['viewRef'].loadData(id)
            } else {
              console.error('Form子组件内部未定义loadData方法')
            }
          } else {
            console.error('Form子组件的editRef未指定')
          }
        })
      }
    }
  }
</script>
<style scoped>
  .areaTable {
    border-collapse: collapse;
    border-spacing: 0;
    overflow-y: auto;
    height: 220px;
    display: block;
    margin-top: 15px
  }

  .areaTable-top {
    padding-right: 8px;
    font-weight: 700;
    width: 70px;
    vertical-align: top;
    padding-top: 6px;
  }

  .areaTable-a {
    white-space: nowrap;
    margin: 0 10px;
    line-height: 30px;
    color: #3d6dcc;
    text-decoration: none;
    padding: 0 10px;
  }

  .select-area-a {
    color: red;
    border: 1px solid;
    border-radius: 30px;
  }
</style>